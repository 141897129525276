// ** React Imports
import { useState, createContext } from "react";

// ** Intl Provider Import
import { IntlProvider } from "react-intl";

// ** Switching document direction Import
import { useRTL } from "../hooks/useRTL";
// ** Core Language Data
import messagesEn from "@assets/data/locales/en.json";
import messagesDe from "@assets/data/locales/de.json";
import messagesAr from "@assets/data/locales/ar.json";
import messagesFr from "@assets/data/locales/fr.json";
import messagesPt from "@assets/data/locales/pt.json";

// ** User Language Data
import userMessagesEn from "@src/assets/data/locales/en.json";
import userMessagesDe from "@src/assets/data/locales/de.json";
import userMessagesAr from "@src/assets/data/locales/ar.json";
import userMessagesFr from "@src/assets/data/locales/fr.json";
import userMessagesPt from "@src/assets/data/locales/pt.json";

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  de: { ...messagesDe, ...userMessagesDe },
  ar: { ...messagesAr, ...userMessagesAr },
  fr: { ...messagesFr, ...userMessagesFr },
  pt: { ...messagesPt, ...userMessagesPt },
};

// ** Create Context
const Context = createContext();

const detectCurrentLang = () => {
  const isExist = localStorage.getItem("locale") != undefined;
  if (isExist) return localStorage.getItem("locale");
  localStorage.setItem("locale", "en");
  return "en";
};

const IntlProviderWrapper = ({ children }) => {
  // custom hook for switch document direction
  const [isRTL, setValue] = useRTL();

  // ** States
  const [locale, setLocale] = useState(detectCurrentLang());
  const [messages, setMessages] = useState(menuMessages[detectCurrentLang()]);

  // ** Switches Language
  const switchLanguage = (lang) => {
    setLocale(lang);
    lang === "ar" ? setValue(true) : setValue(false);
    localStorage.setItem("locale", lang);
    setMessages(menuMessages[lang]);
  };
  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlProviderWrapper, Context as IntlContext };
